export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  console.log("authHeader function run")
  if (user && user.access_token) {
    console.log("Return Authorization header", user.access_token)
    return { Authorization: 'Bearer ' + user.access_token };  // for Spring Boot back-end
  
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}


// export default function authHeader() {
//   let user = JSON.parse(localStorage.getItem('user'));
//   console.log(user)
//   if (user && user.access_token) {
//     return 'Bearer ' + user.access_token; // for Spring Boot back-end
//     // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
//   } else {
//     return "";
//   }
// }
