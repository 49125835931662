import axios from 'axios';

class AuthService {
  
  login(user) {
    const form = new FormData()
    form.append('username', user.username)
    form.append('password', user.password)
    return axios
      .post( '/api/token', form

      )
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        console.log(response.data)
        return response.data;
        
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post('/api/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
