<template>
  <router-view/>
</template>

<script>
import "@/assets/styles/app.scss";

export default {
  name: "App",
};
</script>