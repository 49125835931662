import { createApp } from 'vue'
import App from './App.vue'
import { library, dom} from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import axios from 'axios'
import VueAxios from 'vue-axios'
import "./axios"
import "./services/axios-interceptors" 
import router from "./router";
import store from "./store";


library.add(far, fab, fas);
dom.watch();

const app = createApp(App);
// createApp(App).mount('#app')
app.use(VueAxios, axios)
app.use(router)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app')

