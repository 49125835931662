import axios from 'axios';
import router from '../router';  // Adjust the path as needed
import authHeader from './auth-header';

axios.interceptors.request.use(
    config => {
      // Attach token to headers using authHeader function
      Object.assign(config.headers, authHeader());
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  
  axios.interceptors.response.use(
    response => response,
    error => {
      // Redirect to login if not authorized
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('user'); // Clear user token
        router.push('/login');
      }
      return Promise.reject(error);
    }
  );